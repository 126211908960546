import { Button, Card, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, ListGroup, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon } from "../../components/icon/Icon";
import IFilter from "./IFilter";
import accountService from "../../services/AccountService";
import ExportToExcel from "../exportBtn/ExportToExcel";

function sortValues(values: string[]) {
    const valueSet = new Set(values);
    const valueArray = Array.from(valueSet);

    return valueArray.sort((a, b) => a.localeCompare(b));
};

const SearchBar = (props: IFilter) => {
    return (
        <Form>
            <InputGroup className="input-group-flush input-group-merge input-group-reverse">
                <FormControl    type="search"
                                className="list-search"
                                placeholder="Search"
                                value={props.search}
                                onChange={e => props.onSearchChange(e.target.value)}
                                />
                <InputGroup.Text>
                    <Icon iconName="Search" />
                </InputGroup.Text>
            </InputGroup>
        </Form>
    );

}

const PageSize = (props: IFilter) => {
    const { t } = useTranslation();

    const title = props.pageSize > 0
        ? t("filters.pageCount", { count: props.pageSize })
        : t("filters.pageCountAll");

    return (
        <DropdownButton id="drp-count" size="sm" title={title} variant="light">
            {
                [5, 10, 25, 50].map((number, index) => (
                    <Dropdown.Item  key={index}
                                    active={props.pageSize === number}
                                    onClick={() => props.onPageSizeChange(number)}>
                        {t("filters.pageCount", { count: number })}
                    </Dropdown.Item>
                ))
            }
            <Dropdown.Divider />
            <Dropdown.Item active={props.pageSize === 0} onClick={() => props.onPageSizeChange(0)}>
                {t("filters.pageCountAll")}
            </Dropdown.Item>
        </DropdownButton>
    );
}

interface IFilterDropdown {
    titleKey: string;
    value?: string;
    values: string[];
    onChange: (value: string) => void;
}

const FilterRow = (props: IFilterDropdown) => {
    const { t } = useTranslation();

    return (
        <ListGroup.Item>
            <Row>
                <Col xs={12} md={4}>
                    <small>{t(props.titleKey)}</small>
                </Col>
                <Col xs={12} md={8}>
                    <Form.Select    size="sm"
                                    title={t(props.titleKey)}
                                    value={props.value}
                                    onChange={(e) => props.onChange(e.target.value)}
                                    className="w-100">
                        <option value="">Any</option>
                        {
                            sortValues(props.values)
                                .map((value, index) => <option key={index} value={value}>{value}</option>)
                        }
                    </Form.Select>
                </Col>
            </Row>
        </ListGroup.Item>
    );
}

const MacroClientFilter = (props: IFilter) => (
    <FilterRow  titleKey="filters.helpdesk.client"
                value={props.client}
                values={props.clients}
                onChange={props.onClientChange} />
);

const StatusFilter = (props: IFilter) => (
    <FilterRow  titleKey="filters.helpdesk.status"
                value={props.status}
                values={props.statuses}
                onChange={props.onStatusChange} />
);

const AssigneeFilter = (props: IFilter) => (
    <FilterRow  titleKey="filters.helpdesk.assignee"
                value={props.assignee}
                values={props.assignees.filter(a => !!a).map(a => a.name)}
                onChange={props.onAssigneeChange} />
);

const SystemFilter = (props: IFilter) => (
    <FilterRow  titleKey="filters.helpdesk.system"
                value={props.system}
                values={props.systems}
                onChange={props.onSystemChange} />
);

const ContactFilter = (props: IFilter) => (
    <FilterRow  titleKey="filters.helpdesk.contact"
                value={props.contact}
                values={props.contacts}
                onChange={props.onContactChange} />
);

const TypeFilter = (props: IFilter) => (
    <FilterRow  titleKey="filters.helpdesk.type"
                value={props.type}
                values={props.types}
                onChange={props.onTypeChange} />
);

const FilterArea = (props: IFilter) => {
    const { t } = useTranslation();
    const roles = accountService.getRoles();

    const reset = () => {
        props.onSearchChange("");
        props.onClientChange("");
        props.onStatusChange("");
        props.onAssigneeChange("");
        props.onSystemChange("");
        props.onContactChange("");
        props.onTypeChange("");
    }

    return (
        <Dropdown id="drp-filter">
            <Dropdown.Toggle size="sm" variant="light">
                <Icon iconName="Sliders" endMargin={2} /> {t("filters.filter")}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-card" as={Form}>
                <Card.Header>
                    <h4 className="card-header-title">{t("filters.filters")}</h4>
                    <Button size="sm" variant="link" className="text-reset" type="reset" onClick={reset}>
                        <small>{t("filters.clear")}</small>
                    </Button>
                </Card.Header>
                <Card.Body>
                    <ListGroup variant="flush" className="mt-n4">
                        {roles.includes("MaceHelpdesk") && <MacroClientFilter {...props} />}
                        {roles.includes("MaceHelpdesk") && <ContactFilter {...props} />}
                        <StatusFilter {...props} />
                        <TypeFilter {...props} />
                        <SystemFilter {...props} />
                        <AssigneeFilter {...props} />
                    </ListGroup>
                </Card.Body>
            </Dropdown.Menu>
        </Dropdown>
    );
}

const Filters = (props: IFilter) => {    
    return (
        <Row className="align-items-center">
            <Col xs={12} md>
                <SearchBar {...props} />
            </Col>
            <Col xs="auto" className="me-n3">
                <ExportToExcel tickets={props.tickets} />
            </Col>
            <Col xs="auto" className="me-n3">
                <PageSize {...props} />
            </Col>
            <Col xs="auto">
                <FilterArea {...props} />
            </Col>
        </Row>
    );
}

export default Filters;