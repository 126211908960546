import { useTranslation } from "react-i18next";
import ITicket from "../../models/ITicket";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Status from "../../enums/Status";
import styles from "./Ticket.module.scss";
import PageHeader from "../../components/pageHeader/PageHeader";
import { Alert, Badge, Card, Col, Container, Nav, OverlayTrigger, Row, Spinner, Tab, Tooltip } from "react-bootstrap";
import { Icon } from "../../components/icon/Icon";
import * as icons from 'react-bootstrap-icons';
import TicketNumber from "./ticketNumber/TicketNumber";
import TicketSLATab from "./tabs/SLA";
import dayjs from "dayjs";
import ticketService from "../../services/helpdesk/TicketService";
import CommentsTab from "./tabs/comments/Tab";
import AttachmentsTab from "./tabs/attachments/Tab";
import CommunicationTab from "./tabs/communication/Tab";
import WorkLoggedTab from "./tabs/workLogged/Tab";

interface ITicketProps {
    ticketId: number;
}

interface ITicketTabProps {
    name: string;
    iconName: keyof typeof icons;
}

const DefaultHeader = () => {
    const { t } = useTranslation();
    return <PageHeader title={t("helpdesk.view")} subtitle={t("helpdesk.titles.hd")} />;
};

const TicketTab = (props: ITicketTabProps) => {
    const { t } = useTranslation();

    return (
        <Nav.Item>
            <Nav.Link eventKey={props.name}>
                <span className="d-none d-lg-inline">{t(`helpdesk.ticket.tabs.${props.name}`)}</span>
                <span className="d-inline d-lg-none">
                    <Icon iconName={props.iconName} />
                </span>
            </Nav.Link>
        </Nav.Item>
    );
};

export default function Ticket(props: ITicketProps) {
    const { t } = useTranslation();

    const [ticket, setTicket] = useState<ITicket>(null!);
    const [status, setStatus] = useState<Status>(Status.LOADING);

    useEffect(() => getTicket(props.ticketId, setTicket, setStatus), [props.ticketId]);

    if (status === Status.LOADING) {
        return (
            <>
                <DefaultHeader />
                <div className="text-center">
                    <Spinner variant="dark" />
                </div>
            </>
        );
    }

    if (status === Status.LOAD_ERROR) {
        return (
            <>
                <DefaultHeader />
                <Alert variant="danger">{t("helpdesk.exceptions.loadTicket")}</Alert>
            </>
        );
    }

    const created = dayjs(ticket.created).format("HH:mm DD/MM/YYYY");

    return (
        <>
            <PageHeader title={ticket.title} subtitle={t("helpdesk.titles.hd")} />
            <TicketNumber ticketId={ticket.id} ticketNumber={ticket.ticketNumber} />
            <div className="d-flex gap-2 mb-3">
                <OverlayTrigger placement="top" overlay={<Tooltip>{t("helpdesk.details.priority.title")}</Tooltip>}>
                    <Badge bg="dark">
                        <Icon iconName="ExclamationLg" />
                        <span className="mb-0">{ticket.priority.name}</span>
                    </Badge>
                </OverlayTrigger>
                
                <OverlayTrigger placement="top" overlay={<Tooltip>{t("helpdesk.details.status")}</Tooltip>}>
                    <Badge bg="info">
                        <Icon iconName="Clock" />
                        <span className="mb-0">{ticket.step.name}</span>
                    </Badge>
                </OverlayTrigger>

                {
                    ticket.assignee &&
                    <OverlayTrigger placement="top" overlay={<Tooltip>{t("helpdesk.details.assignee")}</Tooltip>}>
                        <Badge bg="primary">
                            <Icon iconName="Person" />
                            <span className="mb-0">{ticket.assignee.name}</span>
                        </Badge>
                    </OverlayTrigger>
                }
            </div>
            <Tab.Container defaultActiveKey="details">
                <Card className={styles.card}>
                    <Card.Header>
                        <Nav variant="tabs">
                            <TicketTab name="details" iconName="InfoCircle" />
                            <TicketTab name="comms" iconName="ChatLeftText" />
                            <TicketTab name="sla" iconName="Clock" />
                            <TicketTab name="work" iconName="Briefcase" />
                            <TicketTab name="attachments" iconName="Paperclip" />
                            <TicketTab name="comments" iconName="ChatLeft" />
                        </Nav>
                    </Card.Header>
                    <Card.Body>
                        <Tab.Content>
                            <Tab.Pane eventKey="details">
                                <div className={styles.description} dangerouslySetInnerHTML={{ __html: ticket.description! }} />
                                <hr />
                                <Container>
                                    <Row>
                                        <Col>
                                            <h5>{t("helpdesk.details.type")}</h5>
                                            <p>
                                                {ticket.type}
                                                {ticket.issueSubCategory && <span> - {ticket.issueSubCategory}</span>}
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5>{t("helpdesk.details.system")}</h5>
                                            <p>{ticket.systemOrProduct}</p>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <h5>{t("helpdesk.details.contactMethod")}</h5>
                                            <p>{ticket.contactMethod}</p>
                                        </Col>
                                    </Row>
                                </Container>
                            </Tab.Pane>
                            <Tab.Pane eventKey="comms">
                                <CommunicationTab ticket={ticket} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="sla">
                                <TicketSLATab ticket={ticket} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="work">
                                <WorkLoggedTab ticket={ticket} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="attachments">
                                <AttachmentsTab ticket={ticket} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="comments">
                                <CommentsTab ticket={ticket} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Card.Body>
                    <Card.Footer className="text-muted d-flex align-items-center justify-content-between">
                        <OverlayTrigger placement="top" overlay={<Tooltip>{t("helpdesk.details.contact")}</Tooltip>}>
                            <div>
                                <Icon iconName="PersonCircle" endMargin={2} />
                                <span className="mb-0">
                                    <a href={`mailto:${ticket.contact.emailAddress}`}>{ticket.contact.name}</a>
                                </span>
                            </div>
                        </OverlayTrigger>
                        
                        <OverlayTrigger placement="top" overlay={<Tooltip>{t("general.created")}</Tooltip>}>
                            <div>
                                <Icon iconName="Calendar" endMargin={2} />
                                <span className="mb-0">{created}</span>
                            </div>
                        </OverlayTrigger>
                    </Card.Footer>
                </Card>
            </Tab.Container>
        </>
    );
}

function getTicket(
    ticketId: number,
    setTicket: Dispatch<SetStateAction<ITicket>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    ticketService.getTicket(ticketId)
        .then(ticket => {
            setTicket(ticket);
            setStatus(Status.LOAD_SUCCESS);
        })
        .catch(() => setStatus(Status.LOAD_ERROR));
}