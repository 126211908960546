import IStaticValue from "../../models/IStaticValue";
import networkService from "../NetworkService";

/**
 * @description Service for managing helpdesk tickets
 */
class HelpdeskService {

    /**
     * @description Fetches the skills supported for the user's helpdesk
     */
    public async getSkills() : Promise<IStaticValue[]> {
        const result = await networkService.get<IStaticValue[]>("helpdesk/skills");

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IStaticValue[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Fetches the tasks supported for the user's helpdesk
     * @param systemId The ID of the system to filter tasks by (for Mace helpdesk only)
     */
    public async getTasks(systemId?: number) : Promise<IStaticValue[]> {
        const url = systemId || systemId === 0 ? `helpdesk/tasks?systemId=${systemId}` : "helpdesk/tasks";
        const result = await networkService.get<IStaticValue[]>(url);

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IStaticValue[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Fetches the subtasks supported for the selected task on the  user's helpdesk (for Mace helpdesk only)
     * @param systemId The ID of the system to filter subtasks by
     * @param taskId The ID of the task to filter subtasks by
     */
    public async getSubtasks(systemId?: number, taskId?: number) : Promise<IStaticValue[]> {
        const url = `helpdesk/tasks/${taskId}/subtasks?systemId=${systemId}`;
        const result = await networkService.get<IStaticValue[]>(url);

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as IStaticValue[]
                : [];
        }

        throw result.data;
    }

    /**
     * @description Fetches the number of hours worked by the user this month
     */
    public async getHoursWorkedThisMonth() : Promise<number> {
        const result = await networkService.get<number>("helpdesk/hours");

        if (result.isSuccessful) {
            return result.status === 200
                ? result.data as number
                : 0;
        }

        throw result.data;
    }

}

const helpdeskService = new HelpdeskService();
export default helpdeskService;