import Cookies from "js-cookie";
import IAccount from "../models/IAccount";
import networkService from "./NetworkService";
import { jwtDecode } from "jwt-decode";

class AccountService {

    /**
     * @description Gets the current user's account
     */
    async getAccount(): Promise<IAccount> {
        const result = await networkService.get<IAccount>("account");

        if (result.isSuccessful) {
            return result.data as IAccount;
        }

        throw result.data;
    }

    /**
     * @description Gets the roles of the current user
     */
    public getRoles(): string[] {
        const token = Cookies.get("token");
        if (!token) {
            return [];   
        }

        const roleClaim = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";

        const decodedToken = jwtDecode(token);
        const roles = (decodedToken as any)[roleClaim];

        if (roles instanceof Array) {
            return roles;
        }

        if (typeof roles === "string") {
            const role = roles.toString();
            return [role];
        } 

        return [];
    }

    /**
     * @description Switches the current user's client
     * @param clientId The ID of the client to switch to
     */
    async switchClient(clientId: number): Promise<void> {
        const result = await networkService.post("account/client", { clientId });

        if (!result.isSuccessful) {
            throw result.data;
        }

        networkService.refreshToken();
    }
}

const accountService = new AccountService();
export default accountService;