import { BrowserRouter } from "react-router-dom";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { mergeStyles } from "@fluentui/merge-styles";
import { useTranslation } from "react-i18next";
import IAuthoriseProps from "./IAuthoriseProps";
import AuthRoutes from "./Routes";

export default function Authorise(props: IAuthoriseProps) {
    const { t } = useTranslation();

    const backgroundImageUrl = `${process.env.PUBLIC_URL}/img/banner.webp`;
    const backgroundCss = mergeStyles({ backgroundImage: `url('${backgroundImageUrl}')` });

    return (
        <BrowserRouter>
            <main role="main" className="d-flex justify-content-center align-items-center min-vh-100">
                <Container fluid>
                    <Row className="justify-content-center">
                        <Col xs={12} md={5} lg={6} xl={4} className="px-lg-6 my-5 align-self-center">
                            <Logo />
                            <Alert variant="warning">
                                <span>{t("testing_warning")}</span>
                            </Alert>
                            <AuthRoutes {...props} />
                        </Col>
                        <Col xs={12} md={7} lg={6} xl={8} className="d-none d-lg-block">
                            <div className={`${backgroundCss} bg-cover h-100 min-vh-100 mt-n1 me-n3`}></div>
                        </Col>
                    </Row>
                </Container>
            </main>
        </BrowserRouter>
    );
}

const Logo = () => {
    const { t } = useTranslation();
    
    const logo = `${process.env.PUBLIC_URL}/img/dolphin-solutions_logo_blue-black.png`;
    return <img src={logo} alt={t("dolphin.application.long")} className="w-100 mb-3" />;
}