import { Button, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Icon } from "../components/icon/Icon";
import StatCard from "../components/cards/statCard/StatCard";
import Status from "../enums/Status";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ticketService from "../services/helpdesk/TicketService";
import TicketStatus from "../enums/TicketStatus";
import helpdeskService from "../services/helpdesk/HelpdeskService";

export default function Helpdesk() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [openStatus, setOpenStatus] = useState<Status>(Status.LOADING);
    const [closedInMonthStatus, setClosedInMonthStatus] = useState<Status>(Status.LOADING);
    const [onHoldStatus, setOnHoldStatus] = useState<Status>(Status.LOADING);
    const [hoursStatus, setHoursStatus] = useState<Status>(Status.LOADING);
    
    const [openCount, setOpenCount] = useState<number>(0);
    const [closedInMonthCount, setClosedInMonthCount] = useState<number>(0);
    const [onHoldCount, setOnHoldCount] = useState<number>(0);
    const [hoursCount, setHoursCount] = useState<number>(0);
    
    useEffect(() => setOpenTicketsCount(setOpenCount, setOpenStatus), []);
    useEffect(() => setClosedInMonthTicketsCount(setClosedInMonthCount, setClosedInMonthStatus), []);
    useEffect(() => setOnHoldTicketsCount(setOnHoldCount, setOnHoldStatus), []);
    useEffect(() => setHours(setHoursCount, setHoursStatus), []);

    const open =
        openStatus === Status.LOADING
            ? t("general.wait")
            : openStatus === Status.LOAD_SUCCESS
                ? openCount
                : "ERR";

    const closed =
        closedInMonthStatus === Status.LOADING
            ? t("general.wait")
            : closedInMonthStatus === Status.LOAD_SUCCESS
                ? closedInMonthCount
                : "ERR";

    const onHold =
        onHoldStatus === Status.LOADING
            ? t("general.wait")
            : onHoldStatus === Status.LOAD_SUCCESS
                ? onHoldCount
                : "ERR";

    const hours =
        hoursStatus === Status.LOADING
            ? t("general.wait")
            : hoursStatus === Status.LOAD_SUCCESS
                ? hoursCount
                : "ERR";

    const tooltip = <Tooltip id="hd-add-ticket">{t("helpdesk.log")}</Tooltip>;

    return (
        <>
            <Row>
                <Col>
                    <h2>{t("helpdesk.titles.hd")}</h2>
                </Col>
                <Col xs="auto">
                    <OverlayTrigger overlay={tooltip} placement="left">
                        <Button variant='primary' size="sm" className='btn-rounded-circle'
                                onClick={() => navigate("/helpdesk/ticket")}>
                            <Icon iconName='Plus' />
                        </Button>
                    </OverlayTrigger>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={6}>
                    <StatCard   title="Currently open tickets"
                        value={open}
                        icon="List"
                        link='/helpdesk/tickets/open' />
                </Col>
                <Col xs={12} md={6}>
                    <StatCard   title="Tickets closed this month"
                        value={closed}
                        icon="Check"
                        link='/helpdesk/tickets/closed/month' />
                </Col>
                <Col xs={12} md={6}>
                    <StatCard   title="Tickets on hold"
                        value={onHold}
                        icon="Pause"
                        link='/helpdesk/tickets/onhold' />
                </Col>
                <Col xs={12} md={6}>
                    <StatCard   title="Hours worked this month"
                        value={hours}
                        icon="Hourglass" />
                </Col>
            </Row>
        </>
    );
}

function setOpenTicketsCount(
    setCount: Dispatch<SetStateAction<number>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.countTickets(TicketStatus.OPEN)
            .then(count => {
                setCount(count);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}

function setClosedInMonthTicketsCount(
    setCount: Dispatch<SetStateAction<number>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.countTickets(TicketStatus.CLOSED_IN_MONTH)
            .then(count => {
                setCount(count);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}

function setOnHoldTicketsCount(
    setCount: Dispatch<SetStateAction<number>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.countTickets(TicketStatus.ON_HOLD)
            .then(count => {
                setCount(count);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}

function setHours(
    setHours: Dispatch<SetStateAction<number>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        helpdeskService.getHoursWorkedThisMonth()
            .then(hours => {
                setHours(hours);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setStatus(Status.LOAD_ERROR);
    }
}