import { Alert, Container, ListGroup, Spinner } from "react-bootstrap";
import ITicket from "../../../../models/ITicket";
import Status from "../../../../enums/Status";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import IMessage from "../../../../models/IMessage";
import ticketService from "../../../../services/helpdesk/TicketService";
import NewMessageForm from "./NewMessageForm";
import Message from "./Message";

export default function CommunicationTab({ ticket }: { ticket: ITicket }) {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [messages, setMessages] = useState<IMessage[]>([]);

    useEffect(() => getMessages(ticket.id, setMessages, setStatus), [ticket.id]);
    
    return (
        <Container>
            {
                (
                    status === Status.LOADING &&
                    <div className="text-center">
                        <Spinner animation="border" variant="dark" />
                    </div>
                ) ||
                (
                    status === Status.LOAD_ERROR &&
                    <Alert variant="danger">{t("helpdesk.exceptions.loadMessages")}</Alert>
                ) ||
                (
                    [Status.LOAD_SUCCESS, Status.SAVING, Status.SAVE_SUCCESS, Status.SAVE_ERROR].includes(status) &&
                    <div className="d-block">
                        {
                            (messages.length === 0 && <Alert variant="info">{t("helpdesk.comms.noMessages")}</Alert>) ||
                            <ListGroup className="mb-3">
                                {messages.map(message => <Message message={message} />)}
                            </ListGroup>
                        }

                        <NewMessageForm ticketId={ticket.id}
                                        ticketNumber={ticket.ticketNumber}
                                        onMessageSent={() => getMessages(ticket.id, setMessages, setStatus)} />
                    </div>
                )
            }
        </Container>
    );
}

function getMessages(
    ticketId: number,
    setMessages: Dispatch<SetStateAction<IMessage[]>>,
    setStatus: Dispatch<SetStateAction<Status>>
) {
    setStatus(Status.LOADING);

    try {
        ticketService.getMessages(ticketId)
            .then(messages => {
                setMessages(messages);
                setStatus(Status.LOAD_SUCCESS);
            })
            .catch(() => setStatus(Status.LOAD_ERROR));
    } catch {
        setMessages([]);
        setStatus(Status.LOAD_ERROR);
    }
}