import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import IClient from "../../models/IClient";
import Status from "../../enums/Status";
import { Alert, Form, Placeholder } from "react-bootstrap";
import clientService from "../../services/ClientService";
import accountService from "../../services/AccountService";
import { mergeStyles } from "@fluentui/merge-styles";

export default function ClientDropdown() {
    const { t } = useTranslation();

    const [status, setStatus] = useState<Status>(Status.LOADING);
    const [clientId, setClientId] = useState<number>(0);
    const [clients, setClients] = useState<IClient[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setStatus(Status.LOADING);
                
                const clients = await getClients();
                const activeClientId = await getActiveClientId();

                setClients(clients);
                setClientId(activeClientId);

                setStatus(Status.LOAD_SUCCESS);
            } catch {
                setStatus(Status.LOAD_ERROR);
            }
        };

        fetchData();
    }, []);

    const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setClientId(parseInt(e.target.value));
        setStatus(Status.SAVING);

        accountService.switchClient(parseInt(e.target.value))
            .then(() => {
                setStatus(Status.SAVE_SUCCESS);
                setTimeout(() => window.location.reload(), 750);
            })
            .catch(() => setStatus(Status.SAVE_ERROR));
    };
    
    if (status === Status.LOADING) {
        const styles = mergeStyles({
            height: "38px",
            borderRadius: "4px",
            backgroundColor: "#494c6a80"
        });

        return <Placeholder className={styles} animation="wave" />;
    }

    if (status === Status.LOAD_ERROR) {
        return <Alert variant="danger">{t("clients.exceptions.loadClientsShort")}</Alert>
    }

    if (status === Status.SAVING) {
        return <Alert variant="info">{t("general.wait")}</Alert>
    }

    if (status === Status.SAVE_ERROR) {
        return <Alert variant="danger">{t("account.exceptions.switchClientShort")}</Alert>
    }

    if (status === Status.SAVE_SUCCESS) {
        return <Alert variant="success">{t("general.wait")}</Alert>
    }

    return (
        <Form.Select value={clientId} onChange={onChange}>
            <option value={0}>{t("general.pleaseSelect")}</option>
            {clients.map((client) => <option key={client.id} value={client.id}>{client.name}</option>)}
        </Form.Select>
    );
}

async function getActiveClientId() : Promise<number> {
    const account = await accountService.getAccount();
    return account.clientId;
}

async function getClients() {
    return await clientService.getClients();
}