import { useTranslation } from "react-i18next";
import IWorkLogged from "../../../../models/IWorkLogged";
import { Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import dayjs from "dayjs";
import accountService from "../../../../services/AccountService";
import { Icon } from "../../../../components/icon/Icon";

const roles = accountService.getRoles();
const areListItemsActions = roles.includes("MaceHelpdesk") || roles.includes("Administrator");
const showTypeColumn = !roles.includes("MaceHelpdesk");
const detailsColumnSize = showTypeColumn ? 4 : 6;

const Header = () => {
    const { t } = useTranslation();

    return (
        <ListGroupItem variant="light">
            <Container className="d-none d-lg-block">
                <Row>
                    <Col lg={2}>{t("helpdesk.work.table.date")}</Col>
                    <Col lg={2}>{t("helpdesk.work.table.loggedBy")}</Col>
                    {showTypeColumn && <Col lg={2}>{t("helpdesk.work.table.type")}</Col>}
                    <Col lg={detailsColumnSize}>{t("helpdesk.work.table.details")}</Col>
                    <Col lg={2}>{t("helpdesk.work.table.timeSpent")}</Col>
                </Row>
            </Container>
            <Container className="d-lg-none">
                <Row>
                    <Col xs={12}>{t("helpdesk.ticket.tabs.work")}</Col>
                </Row>
            </Container>
        </ListGroupItem>
    );
};

const Work = ({ work }: { work: IWorkLogged }) => {
    const dateLoggedUtc = `${work.dateLogged}Z`;
    const time = dayjs(dateLoggedUtc).format("HH:mm"),
        date = dayjs(dateLoggedUtc).format("DD/MM/YYYY");

    return (
        <ListGroupItem action={areListItemsActions}>
            <Container>
                <Row>
                    <Col xs={12} lg={2}>
                        <div className="d-flex align-items-center">
                            <span className="d-inline d-lg-none text-muted">
                                <Icon iconName="Calendar" endMargin={2} />
                            </span>
                            {date}
                        </div>
                        <div className="d-flex align-items-center">
                            <span className="d-inline d-lg-none text-muted">
                                <Icon iconName="Clock" endMargin={2} />
                            </span>
                            {time}
                        </div>
                    </Col>
                    <Col lg={2}>
                        <div className="d-flex align-items-center">
                            <span className="d-inline d-lg-none text-muted">
                                <Icon iconName="Person" endMargin={2} />
                            </span>
                            {work.loggedBy}
                        </div>
                    </Col>
                    {showTypeColumn && <Col lg={2} className="d-none d-lg-block">{work.type}</Col>}
                    <Col lg={detailsColumnSize} className="mt-3 mt-lg-0" dangerouslySetInnerHTML={{ __html: work.details }} />
                    <Col lg={2} className="d-none d-lg-block">{work.timeSpent}</Col>
                </Row>
            </Container>
        </ListGroupItem>
    );
};

export default function WorkLogged({ work }: { work: IWorkLogged[] }) {
    return (
        <ListGroup>
            <Header />
            {work.map((w, i) => <Work key={i} work={w} />)}
        </ListGroup>
    )
}